import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import Flickity from 'flickity'
import { gsap } from "gsap";
import * as LottiePlayer from "@lottiefiles/lottie-player";

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

document.addEventListener("DOMContentLoaded", function() {

    var imageElement = document.getElementById("stickyImg");
    var contactButton = document.getElementById("contactButton");
    var mobileContactButton = document.getElementById("mobileContactButton");

    window.addEventListener("scroll", function() {
        if (window.scrollY > 120) {
            imageElement.classList.remove("hidden");
            contactButton.classList.remove("-bottom-40px");
            contactButton.classList.add("bottom-[40px]");
            mobileContactButton.classList.remove("-bottom-[50px]");
            mobileContactButton.classList.add("bottom-[15px]");
        } else {
            imageElement.classList.add("hidden");
            contactButton.classList.remove("bottom-[40px]");
            contactButton.classList.add("-bottom-40px");
            mobileContactButton.classList.remove("bottom-[15px]");
            mobileContactButton.classList.add("-bottom-[50px]");
        }

        var scrollPosition = window.scrollY + window.innerHeight;
        var documentHeight = document.documentElement.scrollHeight;
        var distanceToBottom = documentHeight - scrollPosition;

        if (distanceToBottom <= 800 || window.scrollY === 0) {
            contactButton.classList.remove("bottom-[40px]");
            contactButton.classList.add("-bottom-40px");
            mobileContactButton.classList.remove("bottom-[15px]");
            mobileContactButton.classList.add("-bottom-[50px]");
        } else {
            contactButton.classList.remove("-bottom-40px");
            contactButton.classList.add("bottom-[40px]");
            mobileContactButton.classList.remove("-bottom-[50px]");
            mobileContactButton.classList.add("bottom-[15px]");
        }
    });

});

window.addEventListener('load', function () {

	var reviewsCarousel = document.querySelector('.rcarousel');

    if(reviewsCarousel) {
        var rflkty = new Flickity( reviewsCarousel, {
            pageDots: false,
            cellAlign: 'center',
            prevNextButtons: false,
            lazyLoad: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        cellAlign: 'left',
                    }
                }
            ]
        });

        var rnextButtons = document.querySelectorAll('.rnext');
        rnextButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                rflkty.next();
            });
        });

        var rprevButtons = document.querySelectorAll('.rprev');
        rprevButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                rflkty.previous();
            });
        });

        var slideNumber = 0;
        var numberOfSlides = rflkty.slides.length;

        function toggleHidden(element, condition) {
            element.classList.toggle('opacity-50', condition);
        }

        function updateStatus() {
            slideNumber = rflkty.selectedIndex + 1;

            rprevButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === 1);
            });

            rnextButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === numberOfSlides);
            });
        }

        updateStatus();
        rflkty.on('select', updateStatus);

    }

    var carousel = document.querySelector('.carousel');

    if(carousel) {
        var flkty = new Flickity( carousel, {
            pageDots: false,
            cellAlign: 'center',
            prevNextButtons: false,
            lazyLoad: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        cellAlign: 'left',
                    }
                }
            ]
        });

        var nextButtons = document.querySelectorAll('.next');
        nextButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.next();
            });
        });

        var prevButtons = document.querySelectorAll('.prev');
        prevButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.previous();
            });
        });

        var slideNumber = 0;
        var numberOfSlides = flkty.slides.length;

        function toggleHidden(element, condition) {
            element.classList.toggle('opacity-50', condition);
        }

        function updateStatus() {
            slideNumber = flkty.selectedIndex + 1;

            prevButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === 1);
            });

            nextButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === numberOfSlides);
            });
        }

        updateStatus();
        flkty.on('select', updateStatus);

    }

    var sliders = document.querySelectorAll('.slider');

    sliders.forEach(function(slider) {
        var flkty = new Flickity(slider, {
            pageDots: false,
            cellAlign: 'left',
            prevNextButtons: false,
            lazyLoad: 3,
        });

        var nextButtons = document.querySelectorAll('.slider-next');
        nextButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.next();
            });
        });

        var prevButtons = document.querySelectorAll('.slider-prev');
        prevButtons.forEach(function(button) {
            button.addEventListener('click', function() {
                flkty.previous();
            });
        });

        var slideNumber = 0;
        var numberOfSlides = flkty.slides.length;

        function toggleHidden(element, condition) {
            element.classList.toggle('opacity-50', condition);
        }

        function updateStatus() {
            slideNumber = flkty.selectedIndex + 1;

            prevButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === 1);
            });

            nextButtons.forEach(function(button) {
                toggleHidden(button, slideNumber === numberOfSlides);
            });
        }

        updateStatus();
        flkty.on('select', updateStatus);
    });

});